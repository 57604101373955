* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background: #006811;
  
  nav {
    background-color: #507a4e;
    margin-bottom: 5px;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    ul{
      display: flex;
      width: 10%;
      justify-content: space-around;
      align-self: center;

      li{
        list-style: none;
        cursor: pointer;
        color: wheat;
        margin: 10px;
        position: relative;
        left: 20px;
      }
    }

    .search{
      border-radius: 20px;
      border: 2px solid #006811;
      background: transparent;
      color: wheat;
      height: 30px;
      padding: 10px;
      outline: none;
      position: absolute;
      right: 100px;
      top: 20px;
     
      &::placeholder{
        color: wheat;
      }

      &:focus{
        border: 2px solid #507a4e;
        background: #006811;
      }
    }

    .btn{
      margin: 10px;
      background: #006811;
      border: 2px solid #507a4e;
      border-radius: 10px;
      height: 30px;
      width: 80px;
      color: wheat;
    
      &:hover{        
      background: #507a4e;
      border: 2px solid #006811;
      cursor: pointer;
      }
    }
  }

  .info-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    
    .card{
      height: 450px;
      width: 300px;
      margin: 10px;
      overflow: hidden;

      &:hover{
        .text{
          transform: translateY(calc(-100%));
        }
      }
    }
    
    .text{
      display: flex;
      flex-direction: column;
      background: #507a4e;
      transform: translateY(0px);
      padding: 5px;

      h3{
        color: wheat;
        padding: 10px;
        width: 200px;
      }

      p{
       padding: 5px;
      }

      .vote{
        font-weight: 700;
        background: #006811;
        position: absolute;
        align-self: flex-end;
        align-content: center;
        margin: 10px 15px 0  0;
        padding: 5px;
        width: 30px;
      }

      .release-date{
        color: wheat;
        display: inherit;
        justify-content: flex-end;
      }
    }
  }

  .genre{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 10px auto; 
    width: 70%;
 
    .button{
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 500;
      margin: 5px;
      border-radius: 20px;
      border: 2px solid #507a4e;
      margin-left: 20px;
      background: orangered;
      
      &:hover{
        cursor: pointer;
        transform: translateY(-5px);
      }
    }

    .colored {
      background: blue !important;
    }
  }

  .pagenation{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    
    .prev{
      margin: 10px;
      cursor: pointer;
    }
    
    .pgno {
      border: 2px solid orange;
      padding: 20px 25px;
      border-radius: 50px;
    }
    
    .next{
      margin: 10px;
      cursor: pointer;
    }

    .disabled {
      color: gray;
      cursor: not-allowed;
    }
  }
  
  .footer {
    margin-top: 10px;
    background: #507a4e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    padding: 10px;
  }
}

@media screen and (max-width: 400px)
{
  .App nav {
    display: block;

    ul {
      display: flex;
    width: 100%;
    justify-content: space-evenly;
    }

    .search{
      right: 0;
      margin-right: 10px;
      top: 60px;
    }

    .btn{
      position: relative;
      top: 2px;
    }

    .form {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}